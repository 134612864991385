import { $authApi } from ".";
import { apiRoutes } from "../config/api";

const getTransactions = async (data) => {
  const response = await $authApi.get(apiRoutes.TRANSACTIONS, {
    params: data,
  });
  return response?.data;
};
const getTransaction = async (id) => {
  const response = await $authApi.get(apiRoutes.TRANSACTION, {
    params: {
      id,
    },
  });
  return response?.data;
};
const editTransaction = async (data) => {
  const response = await $authApi.put(apiRoutes.TRANSACTIONS, data);
  return response?.data;
};
const deleteTransaction = async (id) => {
  const response = await $authApi.delete(apiRoutes.TRANSACTIONS, {
    data: { id },
  });
  return response?.data;
};

export {
  getTransactions,
  getTransaction,
  editTransaction,
  deleteTransaction
};