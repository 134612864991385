import { $authApi } from ".";
import { apiRoutes } from "../config/api";

const getDocuments = async (data) => {
  const response = await $authApi.get(apiRoutes.DOCUMENTS, {
    params: data,
  });
  return response?.data;
};
const getDocument = async (id) => {
  const response = await $authApi.get(apiRoutes.DOCUMENT, {
    params: {
      id,
    },
  });
  return response?.data;
};
const editDocument = async (data) => {
  const response = await $authApi.postForm(apiRoutes.DOCUMENT_EDIT, data);
  return response?.data;
};
const deleteDocument = async (id) => {
  const response = await $authApi.delete(apiRoutes.DOCUMENTS, {
    data: { id },
  });
  return response?.data;
};
const createDocument = async (data) => {
  const response = await $authApi.postForm(apiRoutes.DOCUMENTS, data);
  return response?.data;
};
export {
  getDocuments,
  getDocument,
  editDocument,
  deleteDocument,
  createDocument,
};
