import { $authApi } from ".";
import { apiRoutes } from "../config/api";

const getStopWords = async (data) => {
  const response = await $authApi.get(apiRoutes.STOPWORDS, {
    params: data,
  });
  return response?.data;
};
const getStopWord = async (id) => {
  const response = await $authApi.get(apiRoutes.STOPWORD, {
    params: {
      id,
    },
  });
  return response?.data;
};
const editStopWord = async (data) => {
  const response = await $authApi.put(apiRoutes.STOPWORDS, data);
  return response?.data;
};
const deleteStopWord = async (id) => {
  const response = await $authApi.delete(apiRoutes.STOPWORDS, {
    data: { id },
  });
  return response?.data;
};
const createStopWord = async (data) => {
  const response = await $authApi.post(apiRoutes.STOPWORDS, data);
  return response?.data;
};
export { getStopWords, getStopWord, editStopWord, deleteStopWord, createStopWord };