import React, { useCallback } from "react";
import { Container } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { IoChevronBackOutline } from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { Link, useNavigate } from "react-router-dom";
import Meta from "../../components/Meta";
import { createStopWord } from "../../services/stopWord";
import Input from "../../components/UI/Input";
import Button from "../../components/UI/Button";

const StopWordCreate = () => {
  const navigate = useNavigate();

  const {
    control,
    register,
    formState: { errors, isValid },
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
  });

  const data = useWatch({ control });

  const onSubmit = useCallback(() => {
    createStopWord(data)
      .then(() => {
        NotificationManager.success("Стоп-слово успешно создано");
        navigate(-1);
      })
      .catch((error) => {
        NotificationManager.error(
          error?.response?.data?.error ?? "Ошибка при сохранении"
        );
      });
  }, [data]);

  return (
    <Container fluid={true}>
      <Meta title="Создать стоп-слово" />
      <section className="box">
        <div className="d-flex justify-content-between align-items-center">
          <Link
            to="/stopwords"
            className="d-inline-flex align-items-center mb-3 fs-09 text-muted"
          >
            <IoChevronBackOutline className="me-2" size={18} /> Назад к списку
          </Link>
          <Button disabled={!isValid} onClick={() => onSubmit()}>
            Сохранить изменения
          </Button>
        </div>
        <h3 className="mb-4">Создать стоп-слово</h3>
        <div className="mb-3">
          <Input
            label="Слово"
            name="value"
            placeholder="Введите слово"
            errors={errors}
            register={register}
            validation={{
              required: "Обязательное поле",
            }}
          />
        </div>
      </section>
    </Container>
  );
};

export default StopWordCreate;
