import React from 'react';
import { HiMagnifyingGlass } from "react-icons/hi2";

const SearchForm = ({className = "", onSubmit, onChange, defaultValue, onKeyDown, rf}) => {
  return (
    <form 
      action="" 
      onSubmit={onSubmit}
      className={"form-search flex-1 " + className}
    >
      <input 
        type="search" 
        ref={rf}
        placeholder="Найти..." 
        defaultValue={defaultValue}
        onKeyDown={onKeyDown}
        onChange={onChange}
      />
    </form>
  );
};

export default SearchForm;