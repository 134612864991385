import moment from "moment";
import React, {
  createRef,
  useCallback,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { Badge, Col, Row, Container } from "react-bootstrap";
import {
  IoCloseOutline,
  IoCreateOutline,
  IoSearchOutline,
  IoTrashOutline,
} from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { Link, useSearchParams } from "react-router-dom";
import DataTable from "../../components/DataTable";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Input from "../../components/UI/Input";
import Loader from "../../components/UI/Loader";
import Select from "../../components/UI/Select";
import CustomModal from "../../components/utils/CustomModal";
// import { getImageURL } from "../../helpers/image";
import { customPrice } from "../../helpers/product";
import { getCategories } from "../../services/category";
import { deleteProduct, getProducts } from "../../services/product";

const Products = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const inputRef = createRef();

  const [products, setProducts] = useState({
    loading: true,
    items: [],
  });
  // const [categories, setCategories] = useState({
  //   loading: true,
  //   items: [],
  // });

  const [selected, setSelected] = useState([]);
  const [modalDelete, setModalDelete] = useState({
    show: false,
    id: false,
  });

  const productColumns = [
    {
      name: "id",
      cell: (row) => <span className="fw-6 text-uppercase">{row.uid}</span>,
    },
    {
      name: "Категория",
      selector: "category",
      cell: (row) => row?.category?.title ?? null,
    },
    {
      name: "Стоимость",
      selector: "price",
      width: 100,
      cell: (row) =>
        row?.modifiers?.length > 0
          ? "от " +
          customPrice(
            Math.min.apply(
              null,
              row.modifiers.map((e) => e.price)
            )
          )
          : customPrice(row.price),
    },
    {
      name: "Дата",
      cell: (row) => moment(row.createdAt).fromNow(),
      width: 150,
    },
    {
      name: "Описание",
      selector: "desc",
      width: 200,
      cell: (row) =>
        row?.desc ? (
          <Link
            className="text-transfer"
            to={process.env.REACT_APP_CLIENT_URL + `/game/lot/${row?.uid}`}
          >
            {row.desc}
          </Link>
        ) : (
          "-"
        ),
    },
    {
      name: "Продавец",
      cell: (row) => (
        <Link to={`/user/${row?.user?.id}`}>{row?.user?.nickname}</Link>
      ),
    },
    {
      selector: "action",
      align: "right",
      cell: (row) => (
        <div>
          {/* <Link
            disabled={selected.length > 0}
            to={process.env.REACT_APP_SITE_URL + "/game/lot/" + row.id}
            target="_blank"
            className="me-4"
          >
            <IoCreateOutline size={22} />
          </Link> */}
          <a
            disabled={selected.length > 0}
            onClick={() =>
              setModalDelete({ show: !modalDelete.show, id: row.id })
            }
          >
            <IoTrashOutline size={20} className="text-danger" />
          </a>
        </div>
      ),
    },
  ];

  const getData = useCallback(async () => {
    getProducts(searchParams)
      .then(
        (res) =>
          res &&
          setProducts((prev) => ({
            ...prev,
            loading: false,
            ...res,
          }))
      )
      .finally(() => setProducts((prev) => ({ ...prev, loading: false })));
  }, [searchParams]);

  const onDelete = useCallback((id) => {
    deleteProduct(id)
      .then(() => {
        getData();
        NotificationManager.success("Товар успешно удален");
        setModalDelete({ show: false, id: false });
      })
      .catch(() => NotificationManager.error("Ошибка при запросе"));
  }, []);

  const onDeleteSelected = useCallback(() => {
    deleteProduct(selected.map((e) => e.item.id))
      .then(() => {
        setSelected([]);
        getData();
        NotificationManager.success("Выбранные товары успешно удалены");
        setModalDelete({ show: false, id: false });
      })
      .catch(() => NotificationManager.error("Ошибка при запросе"));
  }, [selected]);

  const onSearch = useCallback(() => {
    getData();
  }, [searchParams]);

  // useLayoutEffect(() => {
  //   getCategories({ size: 200 })
  //     .then((res) => {
  //       if (res?.items?.length > 0) {
  //         let array = res.items.map((e) => ({ title: e.title, value: e.id }));
  //         setCategories((prev) => ({
  //           ...prev,
  //           loading: false,
  //           items: array,
  //         }));
  //       }
  //     })
  //     .finally(() => setCategories((prev) => ({ ...prev, loading: false })));
  // }, []);

  useLayoutEffect(() => {
    getData();
  }, [searchParams.get("page")]);

  const header = useMemo(() => {
    return (
      <>
        <div className="d-flex align-items-center justify-content-between mb-3">
          <div>
            <h5 className="fw-7">
              {selected.length > 0 ? `Выбрано ${selected.length}` : "Лоты"}
            </h5>
          </div>
          <div className="search-box mx-5">
            <Input
              ref={inputRef}
              placeholder="Найти"
              className="w-100"
              onChange={(e) => {
                searchParams.set("text", e);
                setSearchParams(searchParams);
              }}
              defaultValue={searchParams.get("text")}
              onKeyDown={(e) => e === "Enter" && onSearch()}
            />
          </div>
        </div>
      </>
    );
  }, [selected, searchParams, modalDelete]);

  if (products?.loading) {
    return <Loader full />;
  }

  return (
    <Container fluid={true}>
      <Meta title="Лоты" />
      <section className="box">
        <DataTable
          columns={productColumns}
          onChange={(items) => setSelected(items)}
          data={products.items}
          header={header}
          selectable
          pagination={products.pagination}
        />
        <CustomModal
          title={
            selected.length > 0
              ? `Удаление ${selected.length} элементов`
              : "Удаление элемента"
          }
          show={modalDelete.show}
          setShow={(e) => setModalDelete({ show: e, id: false })}
          footer={
            <>
              <Button
                className="me-3"
                onClick={() =>
                  setModalDelete({ show: !modalDelete.show, id: false })
                }
              >
                Отмена
              </Button>
              <Button
                className="btn-danger"
                onClick={() =>
                  selected.length > 0
                    ? onDeleteSelected()
                    : modalDelete.id && onDelete(modalDelete.id)
                }
              >
                Удалить
              </Button>
            </>
          }
        >
          Вы точно хотите удалить объявление?
        </CustomModal>
      </section>
    </Container>
  );
};

export default Products;
