import React from 'react';
import { Container } from "react-bootstrap";
import Meta from "../../components/Meta";
import SearchForm from '../../components/forms/SearchForm';
import { HiArrowsUpDown, HiMiniStar } from "react-icons/hi2";
import { Link } from "react-router-dom";
import NavPagination from '../../components/NavPagination';

const Reviews = () => {
    return (
        <Container fluid={true}>
            <Meta title="Отзывы" />

            <section className="box p-0">
                <div className="d-flex align-items-center justify-content-between py-3 px-4">
                    <h5>Отзывы</h5>
                    <SearchForm className='ms-5' />
                </div>
                <table>
                    <thead>
                        <tr>
                            <td>
                                <span>От</span>
                            </td>
                            <td>
                                <span>Кому</span>
                            </td>
                            <td>
                                <span>Объявление</span>
                            </td>
                            <td>
                                <button type='button'>
                                    <span>Дата</span>
                                    <HiArrowsUpDown size={15} className='ms-1' />
                                </button>
                            </td>
                            <td>
                                <span>Рейтинг</span>
                            </td>
                            <td></td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <Link to="/" className="user-avatar">
                                    <img src="/images/img-replacement.jpg" alt="replacement" />
                                    <span>Jayvion Simon</span>
                                </Link>
                            </td>
                            <td>
                                <Link to="/" className="user-avatar">
                                    <img src="/images/img-replacement.jpg" alt="replacement" />
                                    <span>Jayvion Simon</span>
                                </Link>
                            </td>
                            <td>
                                <span className='blue'>2 552</span>
                            </td>
                            <td>
                                <div className='d-flex align-items-center'>
                                    <span className='text-gray'>27.05.2023</span>
                                    <span className='fw-5 ms-1'>12:36</span>
                                </div>
                            </td>
                            <td>
                                <div className='d-flex align-items-center'>
                                    <span className='fw-5'>5</span>
                                    <HiMiniStar className='yellow ms-1' />
                                </div>
                            </td>
                            <td>
                                <button type='button' className='btn-danger fs-08 ms-auto'>Заблокировать</button>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Link to="/" className="user-avatar">
                                    <img src="/images/img-replacement.jpg" alt="replacement" />
                                    <span>Jayvion Simon</span>
                                </Link>
                            </td>
                            <td>
                                <Link to="/" className="user-avatar">
                                    <img src="/images/img-replacement.jpg" alt="replacement" />
                                    <span>Jayvion Simon</span>
                                </Link>
                            </td>
                            <td>
                                <span className='blue'>2 552</span>
                            </td>
                            <td>
                                <div className='d-flex align-items-center'>
                                    <span className='text-gray'>27.05.2023</span>
                                    <span className='fw-5 ms-1'>12:36</span>
                                </div>
                            </td>
                            <td>
                                <div className='d-flex align-items-center'>
                                    <span className='fw-5'>5</span>
                                    <HiMiniStar className='yellow ms-1' />
                                </div>
                            </td>
                            <td>
                                <button type='button' className='btn-success-outline fs-08 ms-auto'>Опубликовать</button>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Link to="/" className="user-avatar">
                                    <img src="/images/img-replacement.jpg" alt="replacement" />
                                    <span>Jayvion Simon</span>
                                </Link>
                            </td>
                            <td>
                                <Link to="/" className="user-avatar">
                                    <img src="/images/img-replacement.jpg" alt="replacement" />
                                    <span>Jayvion Simon</span>
                                </Link>
                            </td>
                            <td>
                                <span className='blue'>2 552</span>
                            </td>
                            <td>
                                <div className='d-flex align-items-center'>
                                    <span className='text-gray'>27.05.2023</span>
                                    <span className='fw-5 ms-1'>12:36</span>
                                </div>
                            </td>
                            <td>
                                <div className='d-flex align-items-center'>
                                    <span className='fw-5'>5</span>
                                    <HiMiniStar className='yellow ms-1' />
                                </div>
                            </td>
                            <td>
                                <button type='button' className='btn-danger fs-08 ms-auto'>Заблокировать</button>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Link to="/" className="user-avatar">
                                    <img src="/images/img-replacement.jpg" alt="replacement" />
                                    <span>Jayvion Simon</span>
                                </Link>
                            </td>
                            <td>
                                <Link to="/" className="user-avatar">
                                    <img src="/images/img-replacement.jpg" alt="replacement" />
                                    <span>Jayvion Simon</span>
                                </Link>
                            </td>
                            <td>
                                <span className='blue'>2 552</span>
                            </td>
                            <td>
                                <div className='d-flex align-items-center'>
                                    <span className='text-gray'>27.05.2023</span>
                                    <span className='fw-5 ms-1'>12:36</span>
                                </div>
                            </td>
                            <td>
                                <div className='d-flex align-items-center'>
                                    <span className='fw-5'>5</span>
                                    <HiMiniStar className='yellow ms-1' />
                                </div>
                            </td>
                            <td>
                                <button type='button' className='btn-danger fs-08 ms-auto'>Заблокировать</button>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Link to="/" className="user-avatar">
                                    <img src="/images/img-replacement.jpg" alt="replacement" />
                                    <span>Jayvion Simon</span>
                                </Link>
                            </td>
                            <td>
                                <Link to="/" className="user-avatar">
                                    <img src="/images/img-replacement.jpg" alt="replacement" />
                                    <span>Jayvion Simon</span>
                                </Link>
                            </td>
                            <td>
                                <span className='blue'>2 552</span>
                            </td>
                            <td>
                                <div className='d-flex align-items-center'>
                                    <span className='text-gray'>27.05.2023</span>
                                    <span className='fw-5 ms-1'>12:36</span>
                                </div>
                            </td>
                            <td>
                                <div className='d-flex align-items-center'>
                                    <span className='fw-5'>5</span>
                                    <HiMiniStar className='yellow ms-1' />
                                </div>
                            </td>
                            <td>
                                <button type='button' className='btn-danger fs-08 ms-auto'>Заблокировать</button>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Link to="/" className="user-avatar">
                                    <img src="/images/img-replacement.jpg" alt="replacement" />
                                    <span>Jayvion Simon</span>
                                </Link>
                            </td>
                            <td>
                                <Link to="/" className="user-avatar">
                                    <img src="/images/img-replacement.jpg" alt="replacement" />
                                    <span>Jayvion Simon</span>
                                </Link>
                            </td>
                            <td>
                                <span className='blue'>2 552</span>
                            </td>
                            <td>
                                <div className='d-flex align-items-center'>
                                    <span className='text-gray'>27.05.2023</span>
                                    <span className='fw-5 ms-1'>12:36</span>
                                </div>
                            </td>
                            <td>
                                <div className='d-flex align-items-center'>
                                    <span className='fw-5'>5</span>
                                    <HiMiniStar className='yellow ms-1' />
                                </div>
                            </td>
                            <td>
                                <button type='button' className='btn-danger fs-08 ms-auto'>Заблокировать</button>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Link to="/" className="user-avatar">
                                    <img src="/images/img-replacement.jpg" alt="replacement" />
                                    <span>Jayvion Simon</span>
                                </Link>
                            </td>
                            <td>
                                <Link to="/" className="user-avatar">
                                    <img src="/images/img-replacement.jpg" alt="replacement" />
                                    <span>Jayvion Simon</span>
                                </Link>
                            </td>
                            <td>
                                <span className='blue'>2 552</span>
                            </td>
                            <td>
                                <div className='d-flex align-items-center'>
                                    <span className='text-gray'>27.05.2023</span>
                                    <span className='fw-5 ms-1'>12:36</span>
                                </div>
                            </td>
                            <td>
                                <div className='d-flex align-items-center'>
                                    <span className='fw-5'>5</span>
                                    <HiMiniStar className='yellow ms-1' />
                                </div>
                            </td>
                            <td>
                                <button type='button' className='btn-danger fs-08 ms-auto'>Заблокировать</button>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Link to="/" className="user-avatar">
                                    <img src="/images/img-replacement.jpg" alt="replacement" />
                                    <span>Jayvion Simon</span>
                                </Link>
                            </td>
                            <td>
                                <Link to="/" className="user-avatar">
                                    <img src="/images/img-replacement.jpg" alt="replacement" />
                                    <span>Jayvion Simon</span>
                                </Link>
                            </td>
                            <td>
                                <span className='blue'>2 552</span>
                            </td>
                            <td>
                                <div className='d-flex align-items-center'>
                                    <span className='text-gray'>27.05.2023</span>
                                    <span className='fw-5 ms-1'>12:36</span>
                                </div>
                            </td>
                            <td>
                                <div className='d-flex align-items-center'>
                                    <span className='fw-5'>5</span>
                                    <HiMiniStar className='yellow ms-1' />
                                </div>
                            </td>
                            <td>
                                <button type='button' className='btn-danger fs-08 ms-auto'>Заблокировать</button>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Link to="/" className="user-avatar">
                                    <img src="/images/img-replacement.jpg" alt="replacement" />
                                    <span>Jayvion Simon</span>
                                </Link>
                            </td>
                            <td>
                                <Link to="/" className="user-avatar">
                                    <img src="/images/img-replacement.jpg" alt="replacement" />
                                    <span>Jayvion Simon</span>
                                </Link>
                            </td>
                            <td>
                                <span className='blue'>2 552</span>
                            </td>
                            <td>
                                <div className='d-flex align-items-center'>
                                    <span className='text-gray'>27.05.2023</span>
                                    <span className='fw-5 ms-1'>12:36</span>
                                </div>
                            </td>
                            <td>
                                <div className='d-flex align-items-center'>
                                    <span className='fw-5'>5</span>
                                    <HiMiniStar className='yellow ms-1' />
                                </div>
                            </td>
                            <td>
                                <button type='button' className='btn-danger fs-08 ms-auto'>Заблокировать</button>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Link to="/" className="user-avatar">
                                    <img src="/images/img-replacement.jpg" alt="replacement" />
                                    <span>Jayvion Simon</span>
                                </Link>
                            </td>
                            <td>
                                <Link to="/" className="user-avatar">
                                    <img src="/images/img-replacement.jpg" alt="replacement" />
                                    <span>Jayvion Simon</span>
                                </Link>
                            </td>
                            <td>
                                <span className='blue'>2 552</span>
                            </td>
                            <td>
                                <div className='d-flex align-items-center'>
                                    <span className='text-gray'>27.05.2023</span>
                                    <span className='fw-5 ms-1'>12:36</span>
                                </div>
                            </td>
                            <td>
                                <div className='d-flex align-items-center'>
                                    <span className='fw-5'>5</span>
                                    <HiMiniStar className='yellow ms-1' />
                                </div>
                            </td>
                            <td>
                                <button type='button' className='btn-danger fs-08 ms-auto'>Заблокировать</button>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Link to="/" className="user-avatar">
                                    <img src="/images/img-replacement.jpg" alt="replacement" />
                                    <span>Jayvion Simon</span>
                                </Link>
                            </td>
                            <td>
                                <Link to="/" className="user-avatar">
                                    <img src="/images/img-replacement.jpg" alt="replacement" />
                                    <span>Jayvion Simon</span>
                                </Link>
                            </td>
                            <td>
                                <span className='blue'>2 552</span>
                            </td>
                            <td>
                                <div className='d-flex align-items-center'>
                                    <span className='text-gray'>27.05.2023</span>
                                    <span className='fw-5 ms-1'>12:36</span>
                                </div>
                            </td>
                            <td>
                                <div className='d-flex align-items-center'>
                                    <span className='fw-5'>5</span>
                                    <HiMiniStar className='yellow ms-1' />
                                </div>
                            </td>
                            <td>
                                <button type='button' className='btn-danger fs-08 ms-auto'>Заблокировать</button>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Link to="/" className="user-avatar">
                                    <img src="/images/img-replacement.jpg" alt="replacement" />
                                    <span>Jayvion Simon</span>
                                </Link>
                            </td>
                            <td>
                                <Link to="/" className="user-avatar">
                                    <img src="/images/img-replacement.jpg" alt="replacement" />
                                    <span>Jayvion Simon</span>
                                </Link>
                            </td>
                            <td>
                                <span className='blue'>2 552</span>
                            </td>
                            <td>
                                <div className='d-flex align-items-center'>
                                    <span className='text-gray'>27.05.2023</span>
                                    <span className='fw-5 ms-1'>12:36</span>
                                </div>
                            </td>
                            <td>
                                <div className='d-flex align-items-center'>
                                    <span className='fw-5'>5</span>
                                    <HiMiniStar className='yellow ms-1' />
                                </div>
                            </td>
                            <td>
                                <button type='button' className='btn-danger fs-08 ms-auto'>Заблокировать</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className='py-3 px-4'>
                    <NavPagination />
                </div>
            </section>
        </Container>
    )
}

export default Reviews