import React from 'react';
import { HiChevronLeft, HiChevronRight } from "react-icons/hi2";


const NavPagination = () => {
  return (
    <nav className='nav-pagination'>
        <span>Записей на странице</span>
        <select defaultValue={5}>
            <option value="5">5</option>
            <option value="5">10</option>
            <option value="5">15</option>
            <option value="5">20</option>
        </select>
        <div className='mx-4'>6-10 из 15</div>
        <button type='button' className='nav-pagination-prev'></button>
        <button type='button' className='nav-pagination-next'></button>
    </nav>
  )
}

export default NavPagination