import { $authApi } from ".";
import { apiRoutes } from "../config/api";
const getUsers = async (data) => {
  const response = await $authApi.get(apiRoutes.USER, {
    params: data,
  });
  return response?.data;
};
const getUser = async (id) => {
  const response = await $authApi.get(apiRoutes.USER_ONE, {
    params: {
      id,
    },
  });
  return response?.data;
};
const editUser = async (user) => {
  const response = await $authApi.post(apiRoutes.USER_EDIT, user);
  return response?.data;
};
const blockedUser = async (data) => {
  const response = await $authApi.post(apiRoutes.USER_BLOCKED, data);
  return response?.data;
};
const deleteUser = async (id) => {
  const response = await $authApi.delete(apiRoutes.USER, {
    data: { id },
  });
  return response?.data;
};

const getSessions = async ({ page, limit, userId }) => {
  const response = await $authApi.get(apiRoutes.SESSIONS, {
    params: { page, limit, userId },
  });
  return response?.data;
};
const deleteSession = async (data) => {
  const response = await $authApi.delete(apiRoutes.SESSIONS, {
    data,
  });
  return response?.data;
};

export { deleteUser, blockedUser, editUser, getUser, getUsers, getSessions, deleteSession };