import moment from "moment";
import React, {
  createRef,
  useCallback,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import {
  IoCloseOutline,
  IoSearchOutline,
  IoTrashOutline
} from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import DataTable from "../../components/DataTable";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Input from "../../components/UI/Input";
import Loader from "../../components/UI/Loader";
import CustomModal from "../../components/utils/CustomModal";
import { deleteHistory, getHistories } from "../../services/history";

const Histories = () => {
  const user = useSelector((state) => state.auth.user);

  const inputRef = createRef();
  const [searchParams, setSearchParams] = useSearchParams();
  const [histories, setHistories] = useState({
    loading: true,
    items: [],
  });

  const [selected, setSelected] = useState([]);
  const [modalDelete, setModalDelete] = useState({
    show: false,
    id: false,
  });

  const historyColumns = [
    {
      name: "Браузер",
      selector: "brand",
    },
    {
      name: "Устройство",
      selector: "osName",
    },
    {
      name: "Версия",
      selector: "osVersion",
    },
    {
      name: "IP",
      selector: "ip",
    },
    { name: "Дата", cell: (row) => moment(row.createdAt).format("kk:mm  DD.MM.YYYY") },
  ];

  const header = useMemo(() => {
    return (
      <>
        <div className="d-flex align-items-center justify-content-between mb-4">
          <h5 className="fw-7">История</h5>
        </div>
      </>
    );
  }, [selected, searchParams, modalDelete]);

  const getData = useCallback(async () => {
    getHistories(searchParams)
      .then((res) =>
        setHistories((prev) => ({
          ...prev,
          loading: false,
          ...res,
        }))
      )
      .finally(() => setHistories((prev) => ({ ...prev, loading: false })));
  }, [searchParams]);

  const onDelete = useCallback((id) => {
    deleteHistory(id)
      .then(() => {
        getData();
        NotificationManager.success("История успешно удалена");
        setModalDelete({ show: false, id: false });
      })
      .catch((err) => {
        err?.response?.data?.error ??
          NotificationManager.error("Ошибка при запросе");
      });
  }, []);

  const onDeleteSelected = useCallback(() => {
    deleteHistory(selected.map((e) => e.item.id))
      .then(() => {
        setSelected([]);
        getData();
        NotificationManager.success("Выбранные истории успешно удалены");
        setModalDelete({ show: false, id: false });
      })
      .catch((err) =>
        NotificationManager.error(
          err?.response?.data?.error ?? "Ошибка при запросе"
        )
      );
  }, [selected]);

  const onSearch = useCallback(() => {
    getData();
  }, [searchParams]);

  useLayoutEffect(() => {
    getData();
  }, [searchParams.get("page")]);

  if (histories.loading) {
    return <Loader full />;
  }

  return (
    <>
      <Meta title="История" />
      <DataTable
        columns={historyColumns}
        onChange={(items) => setSelected(items)}
        data={histories.items}
        header={header}
        selectable
        pagination={histories.pagination}
      />
      <CustomModal
        title={
          selected.length > 0
            ? `Удаление ${selected.length} элементов`
            : "Удаление элемента"
        }
        show={modalDelete.show}
        setShow={(e) => setModalDelete({ show: e, id: false })}
        footer={
          <>
            <Button
              className=" me-3"
              onClick={() =>
                setModalDelete({ show: !modalDelete.show, id: false })
              }
            >
              Отмена
            </Button>
            <Button
              className="btn-primary"
              onClick={() =>
                selected.length > 0
                  ? onDeleteSelected()
                  : modalDelete.id && onDelete(modalDelete.id)
              }
            >
              Удалить
            </Button>
          </>
        }
      >
        Вы точно хотите удалить?
      </CustomModal>
    </>
  );
};

export default Histories;
