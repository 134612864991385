import moment from "moment";
import React, {
  createRef,
  useCallback,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { Col, Form, Row, Container } from "react-bootstrap";
import {
  IoCloseOutline,
  IoCreateOutline,
  IoSearchOutline,
  IoTrashOutline,
} from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { Link, useSearchParams } from "react-router-dom";
import DataTable from "../../components/DataTable";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Info from "../../components/UI/Info";
import Loader from "../../components/UI/Loader";
import CustomModal from "../../components/utils/CustomModal";
import { deleteCategory, getCategories } from "../../services/category";
import { useSelector } from "react-redux";
import Input from "../../components/UI/Input";

const Categories = () => {
  const user = useSelector((state) => state.auth.user);

  const inputRef = createRef();
  const [searchParams, setSearchParams] = useSearchParams();
  const [categories, setCategories] = useState({
    loading: true,
    items: [],
  });

  const [selected, setSelected] = useState([]);
  const [modalDelete, setModalDelete] = useState({
    show: false,
    id: false,
  });

  const categoryColumns = [
    {
      name: "Название",
      selector: "title",
    },
    {
      name: "Комиссия",
      selector: "commission",
      align: "center",
      size: 80,
    },
    {
      name: "Объявления",
      align: "center",
      selector: "productCount",
      size: 80,
    },
    {
      selector: "action",
      align: "right",
      cell: (row) => (
        <div>
          <Link
            disabled={selected.length > 0}
            to={"/game/" + row.id}
            className="me-4"
          >
            <IoCreateOutline size={22} />
          </Link>
          <a
            disabled={selected.length > 0}
            onClick={() =>
              setModalDelete({ show: !modalDelete.show, id: row.id })
            }
          >
            <IoTrashOutline size={20} className="text-danger" />
          </a>
        </div>
      ),
    },
  ];

  const header = useMemo(() => {
    return (
      <div className="mb-4 d-flex align-items-center justify-content-between">
        <div>
          <h5 className="fw-7">
            {selected.length > 0 ? `Выбрано ${selected.length}` : "Игры"}
          </h5>
        </div>
        {user.role == 1 && (
          <Link className="btn btn-primary ms-4" to="create">
            Добавить игру
          </Link>
        )}
        <div className="search-box mx-4">
          <Input
            // readOnly={false}
            ref={inputRef}
            placeholder="Найти"
            className="w-100"
            onChange={(e) => {
              searchParams.set("text", e);
              setSearchParams(searchParams);
            }}
            rightIcon={() => <IoSearchOutline size={22} />}
            defaultValue={searchParams.get("text")}
            rightIconClick={() => onSearch()}
            onKeyDown={(e) => e === "Enter" && onSearch()}
          />
          {/* {searchParams.get("text")?.length > 0 && (
            <Button
              className="btn-light ms-3"
              onClick={() => {
                searchParams.delete("text");
                setSearchParams(searchParams);
                onSearch();
                if (inputRef.current) {
                  inputRef.current.value = "";
                }
              }}
            >
              <IoCloseOutline size={22} />
            </Button>
          )} */}
        </div>
      </div>
    );
  }, [selected, searchParams, modalDelete]);

  const getData = useCallback(async () => {
    getCategories(searchParams)
      .then((res) =>
        setCategories((prev) => ({
          ...prev,
          loading: false,
          ...res,
        }))
      )
      .finally(() => setCategories((prev) => ({ ...prev, loading: false })));
  }, [searchParams]);

  const onDelete = useCallback((ids) => {
    deleteCategory(ids)
      .then(() => {
        getData();
        NotificationManager.success("Игра успешно удалена");
        setModalDelete({ show: false, id: false });
      })
      .catch((err) => {
        err?.response?.data?.error ??
          NotificationManager.error("Ошибка при запросе");
      });
  }, []);

  const onDeleteSelected = useCallback(() => {
    deleteCategory(selected.map((e) => e.item.id))
      .then(() => {
        setSelected([]);
        getData();
        NotificationManager.success("Выбранные игры успешно удалены");
        setModalDelete({ show: false, id: false });
      })
      .catch((err) =>
        NotificationManager.error(
          err?.response?.data?.error ?? "Ошибка при запросе"
        )
      );
  }, [selected]);

  const onSearch = useCallback(() => {
    getData();
  }, [searchParams]);

  useLayoutEffect(() => {
    getData();
  }, [searchParams.get("page")]);

  if (categories.loading) {
    return <Loader full />;
  }

  return (
    <Container fluid={true}>
      <Meta title="Игры" />
      <section className="box">
        <DataTable
          columns={categoryColumns}
          onChange={(items) => setSelected(items)}
          data={categories.items}
          header={header}
          selectable
          pagination={categories.pagination}
        />
        <CustomModal
          title={
            selected.length > 0
              ? `Удаление ${selected.length} элементов`
              : "Удаление элемента"
          }
          show={modalDelete.show}
          setShow={(e) => setModalDelete({ show: e, id: false })}
          footer={
            <>
              <Button
                className=" me-3"
                onClick={() =>
                  setModalDelete({ show: !modalDelete.show, id: false })
                }
              >
                Отмена
              </Button>
              <Button
                className="btn-primary"
                onClick={() =>
                  selected.length > 0
                    ? onDeleteSelected()
                    : modalDelete.id && onDelete(modalDelete.id)
                }
              >
                Удалить
              </Button>
            </>
          }
        >
          Вы точно хотите удалить игру(-ы)?
        </CustomModal>
      </section>
    </Container>
  );
};

export default Categories;
