import React, { useRef, useState } from "react";
import { IoTrashOutline } from "react-icons/io5";
import { getImageURL } from "../helpers/image";

const DragDropFile = ({ onChange, file, title = "Выберите изображение" }) => {
  const [dragActive, setDragActive] = useState(false);

  const inputRef = useRef(null);

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      onChange(e.dataTransfer.files);
    }
  };

  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      onChange(e.target.files);
    }
  };

  const onButtonClick = () => {
    inputRef.current.click();
  };

  return (
    <>
      <input
        ref={inputRef}
        type="file"
        id="input-file-upload"
        className="d-none"
        multiple={true}
        onChange={handleChange}
      />
      {file && file.length > 0 ? (
        <div className="position-relative">
          <img
            src={Array.isArray(file) ? getImageURL({ path: file }) : file}
            className="upload-box-img"
          />
          <div className="d-flex flex-row">
            <a
              className="btn btn-primary-outline w-100 mt-2 me-2 upload-box-btn"
              onClick={onButtonClick}
            >
              Изменить
            </a>
            <a className="btn btn-danger-outline mt-2 upload-box-btn">
              <IoTrashOutline size={24} />
            </a>
          </div>
        </div>
      ) : (
        <div
          className="position-relative"
          onDragEnter={handleDrag}
          onSubmit={(e) => e.preventDefault()}
        >
          <div
            htmlFor="input-file-upload"
            className={
              "upload-box product d-flex flex-column align-items-center justify-content-center" +
              (dragActive ? " active" : "")
            }
          >
            <img src="/images/upload-image-product.svg" height={100} />
            <h5 className="mt-3 mb-2 fs-09 fw-7">{title}</h5>
            <p className="text-muted text-center fs-08">
              Перетащите файл сюда или нажмите{" "}
              <a className="text-success" onClick={onButtonClick}>
                обзор
              </a>{" "}
              чтобы загрузить файл с компьютера
            </p>
            <a
              className="mt-3 btn btn-sm btn-primary-outline"
              onClick={onButtonClick}
            >
              Выбрать файл
            </a>
          </div>
          {dragActive && (
            <div
              className="drag-file-element"
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={handleDrop}
            ></div>
          )}
        </div>
      )}
    </>
  );
};

export default DragDropFile;
